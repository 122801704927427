<template>
  <v-text-field
    :label="label"
    :value="value"
    readonly
    filled
    background-color="transparent"
    hide-details
  >
    <template slot="append">
      <v-btn icon tile @click="show = true">
        <v-icon>mdi-square-edit-outline</v-icon>
      </v-btn>
      <ObjectSelector
        v-model="show"
        type="page"
        :single="true"
        @select="submit"
      ></ObjectSelector>
    </template>
  </v-text-field>
</template>

<script>
import ObjectSelector from "../../components/ObjectSelector.vue";
export default {
  components: { ObjectSelector },
  model: {
    prop: "value",
    event: "change",
  },
  props: ["label", "value"],
  data() {
    return {
      show: false,
    };
  },
  methods: {
    submit(item) {
      if (item) this.change(item.id);
    },
    change(value) {
      this.$emit("change", value);
    },
  },
};
</script>
